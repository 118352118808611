import { location } from './window';

const toURL = path =>
  `${location.protocol()}//${location.host()}${path}`;

const get = (path, { headers = {} } = {}) =>
  fetch(toURL(path), {
    method: 'GET',
    headers,
  });

const post = (path, { data = {}, headers = {} } = {}) =>
  fetch(toURL(path), {
    method: 'POST',
    headers,
    body: data,
  });

export const http = { get, post };
