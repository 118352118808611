<template>
  <q-dialog transition-show="slide-down"
            transition-hide="slide-up"
            v-model="showing">
    <q-card class="bg-grey-9" style="width: 700px; max-width: 80vw;">
      <q-toolbar dark class="bg-grey-9 text-white">
        <h2 class="text-h6"
            style="margin-top: 0; margin-bottom: 0;">
          Author Search
        </h2>

        <q-space />

        <q-btn dark
               flat
               round
               dense
               icon="close"
               text-color="white"
               v-close-popup />
      </q-toolbar>

      <q-card-section class="bg-grey-9 shadow-4"
                      role="region"
                      aria-label="Author Search">
        <q-input dark
                 dense
                 square
                 filled
                 autofocus
                 clearable
                 type="search"
                 placeholder="Search Author By Name..."
                 color="red"
                 v-model="query">
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>

      <q-card-section class="bg-white"
                      role="region"
                      aria-label="Author List">
        <div class="q-py-md">
          <authors-list :authors="authors" />
        </div>
      </q-card-section>

      <q-card-actions align="right"
                      class="bg-grey-9 shadow-up-4">
        <q-btn flat
               label="Close"
               text-color="white"
               v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import AuthorsList from './AuthorsList';


const searchByName = query => ({ attributes }) => {
  const name = `${attributes.firstName} ${attributes.lastName}`.toLowerCase();
  return name.includes(query.toLowerCase());
};

const sortByName = ({ attributes: a }, { attributes: b }) =>
  a.lastName.localeCompare(b.lastName)

export default {
  components: {
    AuthorsList,
  },
  computed: {
    showing: {
      get() {
        return this.showAuthorSearch;
      },
      set(show) {
        this.toggleAuthorSearch({ show });
      },
    },
    query: {
      get() {
        return this.authorNameFilter;
      },
      set(value) {
        this.setAuthorFilter({ value });
      },
    },
    authors() {
      if (!this.query) return this.allAuthors.sort(sortByName);
      return this.allAuthors.filter(searchByName(this.query)).sort(sortByName);
    },
    ...mapGetters('authors', {
      allAuthors: 'all',
    }),
    ...mapState('timeline', [
      'showAuthorSearch',
      'authorNameFilter',
    ]),
  },
  methods: {
    ...mapMutations('timeline', [
      'toggleAuthorSearch',
      'setAuthorFilter',
    ]),
  },
}
</script>
