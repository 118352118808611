<template>
  <q-page id="timeline-page"
          style="min-height: unset;"
          :class="pageClasses">
    <h1 style="display: none;">Reading Like a Victorian</h1>
    <div class="header-image relative-position"
         role="region"
         aria-label="Instructions">
      <q-img :src="heroSrc"
             alt="Reading Like a Victorian wordmark"
             style="width: 100vw; height: auto;"
             v-intersection="imageVisible">
        <div class="absolute-top text-subtitle2 text-center q-mt-lg">
          <div>
            When you read a Victorian serial novel in paperback form,
            you are “bingeing” it as you would a completed TV series.
            Nineteenth-century audiences used to read novels in
            installments, following many plots at the same time while they
            waited for the next issue to appear. Click on the timeline
            below to access Victorian serial novel installments online,
            so you can try “reading like a Victorian.”
          </div>

          <!-- <q-separator spaced dark /> -->

          <q-btn unelevated
                 id="launch-timeline-btn"
                 color="primary"
                 label="Launch Timeline"
                 @click="scrollToTimeline" />
          <div class="text-body2 q-pt-md">
            Launch the timeline and use the left and right keyboard arrows
            to navigate it
            </div>
        </div>
      </q-img>
    </div>

    <q-card flat
            square
            id="timeline"
            ref="timeline"
            :class="cardClasses"
            @mouseenter.once="timelineLaunched = true">
      <div class="column">
        <div id="main"
             class="col-auto"
             role="region"
             aria-label="Timeline">
          <timeline-grid :show-controls="timelineLaunched" />

          <q-inner-loading :showing="!loaded">
            <q-spinner-grid size="10em"
                            color="red"
                            :thickness="5" />
          </q-inner-loading>
        </div>

        <q-card-section class="footer text-white q-pa-md col-2">
          <osu-footer alternateFormatContact="readinglikeavictorian@gmail.com"
                      :wordmark-src="OSUWordmarkSrc">
            <small>
              <a href="/dataset" download>Full CSV dataset download</a>
            </small>
          </osu-footer>
        </q-card-section>
      </div>
    </q-card>

    <author-search-modal />
    <about-us-modal />
  </q-page>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { scroll } from 'quasar';
import AuthorSearchModal from '../components/AuthorSearchModal';
import AboutUsModal from '../components/AboutUsModal';
import OsuFooter from "@asctech/vue-osu-footer";
import TimelineGrid from '../components/TimelineGrid';
import TimelineHero from '../images/timeline-hero.png';
import OSUWordmark from '../images/osu-wordmark.png';

const { getScrollTarget, setScrollPosition } = scroll

export default {
  name: 'PageIndex',
  components: {
    AuthorSearchModal,
    AboutUsModal,
    OsuFooter,
    TimelineGrid,
  },
  provide() {
    return {
      scrollToTimeline: this.scrollToTimeline,
    };
  },
  data() {
    return {
      timelineLaunched: false,
    };
  },
  computed: {
    heroSrc() {
      return TimelineHero;
    },
    OSUWordmarkSrc() {
      return "https://bux.osu.edu/img/osu-logos/horiz/osu-horiz-white.svg";
    },
    pageClasses() {
      return {
        'flex': true,
        'full-width': true,
        'full-height': true,
        'bg-grey-1': this.selected ? false : true,
        'bg-grey-5': this.selected ? true : false,
      };
    },
    cardClasses() {
      return {
        'bg-grey-1': this.selected ? false : true,
        'bg-grey-5': this.selected ? true : false,
        'full-width': true,
        'timeline-card': true,
      };
    },
    ...mapState('timeline', ['loaded', 'selected']),
  },
  methods: {
    scrollToTimeline() {
      const el = this.$refs.timeline.$el || this.$refs.timeline;

      const target = getScrollTarget(el);
      const offset = el.offsetTop;
      const duration = 2000;

      setScrollPosition(target, offset, duration);
    },
    imageVisible(entry) {
      if (this.timelineLaunched) return;

      const ratio = (entry.intersectionRatio * 100).toFixed(0);
      this.timelineLaunched = ratio <= 50;
    },
    ...mapActions('publications', ['loadPublications']),
    ...mapMutations('timeline', ['setLoaded']),
  },
  async created() {
    try {
      await this.loadPublications();
      this.setLoaded();
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
}
</script>

<style lang="scss">
#timeline-page {
  .header-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .q-img {
      margin: 0 auto;

      height: 75vh !important;

      &__content {
        top: calc((100vh - 792px) / 4) !important;
        left: 25% !important;

        width: 50% !important;

        & > div {
          font-size: 1.5rem;
          line-height: 2.4rem;

          background: transparent;
        }
      }
    }
  }

  .timeline-card {
    // padding:  8px
    // height: 100%;

    .timeline-grid__grid {
      max-height: calc(100vh - 180px);
      min-height: calc(100vh - 260px);

      overflow-y: scroll;
    }

    .footer {
      background: #333;
      a {
        color: #fff;
      }
      p {
        font-size: 12px;
        text-align: left;
        margin: 0;
      }
      img {
        margin-bottom: -5px;
      }
    }
  }

  .bg-rlv-color-1 {
    background: #faaa51 !important;
  }
  .bg-rlv-color-2 {
    background: #7bd5a9 !important;
  }
  .bg-rlv-color-3 {
    background: #fbcd53 !important;
  }
  .bg-rlv-color-4 {
    background: #b2d8f9 !important;
  }
  .bg-rlv-color-5 {
    background: #faceae !important;
  }
  .bg-rlv-color-6 {
    background: #d0aac7 !important;
  }
  .bg-rlv-color-7 {
    background: #b99870 !important;
  }
  .bg-rlv-color-8 {
    background: #efb0b0 !important;
  }
  .bg-rlv-color-9 {
    background: #e9e389 !important;
  }
  .bg-rlv-color-10 {
    background: #b3ba98 !important;
  }
  .bg-rlv-color-11 {
    background: #bf3217 !important;
  }
  .bg-rlv-color-12 {
    background: #82c0f7 !important;
  }
  .bg-rlv-color-13 {
    background: #add0b0 !important;
  }
  .bg-rlv-color-14 {
    background: #c79499 !important;
  }
  .bg-rlv-color-15 {
    background: #899cfb !important;
  }
  .bg-rlv-color-16 {
    background: #d27e8c !important;
  }
  .bg-rlv-color-17 {
    background: #d27e8c !important;
  }
  .bg-rlv-color-18 {
    background: #888bb4 !important;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-family: proximanova, Helvetica Neue, Helvetica, Arial, sans-serif;
  }
}

.osu-navbar {
  &__container {
    width: auto;
  }
}

#launch-timeline-btn {
  margin-top: 200px;
}

#osu-footer {
  .osu-logo {
    width: 250px;
  }
}

@media (max-width: 1280px) {
  #timeline-page {
    .header-image {
      .q-img__content {
        top: calc((100vh - 592px) / 10) !important;
        left: 12.5% !important;
        width: 75% !important;

        & > div {
          font-size: 1.5rem;
          line-height: 1.9rem;
        }
      }
    }
  }

  #launch-timeline-btn {
    margin-top: 100px;
  }
}

@media (max-width: 599px) {
  #timeline-page {
    .header-image {
      .q-img__content {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;

        & > div {
          font-size: 0.95rem;
          line-height: 1rem;
        }
      }
    }
  }

  #launch-timeline-btn {
    margin-top: 25px;
  }
}
</style>
