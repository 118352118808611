<template>
  <div class="text-grey-2">
    <div class="row justify-between">
      <h3 class="text-subtitle2 text-grey-5 col-auto"
          style="margin-top: 0; margin-bottom: 0;">
        {{ publishPeriod }}
      </h3>

      <div class="col-auto row justify-end">
        <template v-if="genres">
          <q-badge v-for="genre in genres"
                  class="col-auto q-ml-sm"
                  text-color="dark"
                  aria-label="Genre"
                  :key="genre"
                  :color="publication.color.bg"
                  @click="genreClick(genre)">
              {{ genre }}
          </q-badge>
        </template>
      </div>
    </div>

    <h2 class="text-h4"
        style="margin-top: 0; margin-bottom: 0;">
      {{ title }}
    </h2>

    <div class="text-subtitle1 text-grey-5" v-if="authors">
      {{ authors }}
    </div>

    <q-separator dark
                 inset
                 spaced
                 style="margin: 4px 0;"
                 v-if="notes" />
    <div class="text-caption" v-if="notes">
      <div v-html="notes" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { areEqual, monthName } from '../utils/date';

export default {
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.publication.attributes.title;
    },
    authors() {
      return this.publication.authors
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(', ');
    },
    genres() {
      return this.publication.genre;
    },
    languages() {
      return this.publication.language;
    },
    notes() {
      return this.publication.attributes.notes;
    },
    start() {
      const { month, year } = this.publication.start;

      return `${monthName(month)} ${year}`;
    },
    end() {
      const { month, year } = this.publication.end;

      return `${monthName(month)} ${year}`;
    },
    publishPeriod() {
      return areEqual(this.publication.start, this.publication.end)
        ? this.start
        : `${this.start} - ${this.end}`;
    },
  },
  methods: {
    ...mapMutations('timeline', [
      'setGenreFilter',
      'setFiltersStatus',
    ]),
    genreClick: function(genre) {
      this.setFiltersStatus({active: true});
      this.setGenreFilter({genres: [genre]});
    }
  },
};
</script>

<style lang="scss">
</style>
