<template>
  <q-list bordered separator>
    <author-publication v-for="pub in publications"
                        :key="pub.id"
                        :publication="pub" />
  </q-list>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthorPublication from './AuthorPublication';

const hasPublicationDate = ({ attributes: { start } } = {}) =>
  start && start.month && start.year

export default {
  components: {
    AuthorPublication,
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
  computed: {
    publicationIds() {
      const data = this.author.relationships.publications.data || [];
      return data.map(({ id }) => id);
    },
    publications() {
      return this.publicationIds.map(this.findPublication)
                                .filter(hasPublicationDate);
    },
    ...mapGetters('publications', {
      findPublication: 'find',
    }),
  },
};
</script>
