<script>
export default {
  functional: true,
  render(h, { props: { dates } }) {
    const years = dates.reduce((years, { year }) => {
      if (!years[year]) return { ...years, [year]: 1 };
      else return { ...years, [year]: years[year] + 1 };
    }, {});

    return h('div', { class: 'row' }, Object.keys(years).map(year =>
      h('div', { class: `col-${years[year]} text-body1 text-red` }, [year])),
    );
  },
};
</script>
