var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sources.length)?_c('q-toolbar',{class:_vm.toolbarClasses,attrs:{"dense":""}},[_vm._l((_vm.partSources),function(src){return _c('a',{key:src.id,attrs:{"href":src.attributes.url,"target":"_blank"}},[_c('q-btn',{staticClass:"q-mr-sm",attrs:{"text-color":"dark","size":"11px","color":_vm.publication.color.bg,"label":_vm.nopartLabel(src.attributes)}})],1)}),_vm._v(" "),_vm._l((_vm.chapters),function(srcs,bvc){return _c('q-btn-dropdown',{key:bvc,staticClass:"q-mr-sm",attrs:{"cover":"","size":"11px","role":"menu","text-color":"dark","aria-haspopup":"true","aria-expanded":_vm.chapterDropdownExpanded[bvc] ? 'true' : 'false',"color":_vm.publication.color.bg,"label":("" + (_vm.bvc_string(bvc)))},on:{"show":function () {
	var _obj;

	return (_vm.chapterDropdownExpanded = Object.assign({}, _vm.chapterDropdownExpanded, ( _obj = {}, _obj[bvc] = true, _obj )));
},"hide":function () {
	var _obj;

	return (_vm.chapterDropdownExpanded = Object.assign({}, _vm.chapterDropdownExpanded, ( _obj = {}, _obj[bvc] = false, _obj )));
}}},[_c('q-list',{staticClass:"sources-dropdown"},[_c('q-item-label',{class:"text-bold text-dark",attrs:{"header":""}},[_vm._v("\n        "+_vm._s(_vm.bvc_string(bvc))+"\n      ")]),_vm._v(" "),_vm._l((srcs),function(src){return _c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],key:src.id,attrs:{"clickable":""}},[_c('q-item-section',[_c('a',{class:"text-dark",attrs:{"href":src.attributes.url,"title":("Read " + (_vm.bvc_string(bvc)) + " on " + (src.attributes.source)),"target":"_blank","role":"menuitem"}},[_c('q-item-label',[_vm._v("\n              "+_vm._s(src.attributes.source)+"\n            ")])],1)])],1)})],2)],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }