<template>
  <q-layout class="app-layout" view="lHh Lpr lFf">
    <q-header elevated>
      <osu-navbar />
      <a class="skip-to-content-link" href="#main">
        Skip to content
      </a>
      <nav>
        <q-toolbar dark class="page-toolbar bg-dark">
          <a href="/">
            <q-avatar rounded>
              <img :src="logoSrc" alt="readling like a victorian home">
            </q-avatar>
          </a>

          <q-separator dark vertical inset spaced />

          <q-btn outline
                color="red"
                label="Filters"
                @click="() => setFiltersStatus({ active: false })"
                aria-haspopup="true"
                aria-expanded="true"
                v-if="filtersActive" />
          <q-btn flat
                label="Filters"
                @click="() => setFiltersStatus({ active: true })"
                aria-haspopup="true"
                aria-expanded="false"
                v-else />
          <q-btn flat
                 label="Jump to Date"
                 class="q-mx-sm"
                 aria-haspopup="true"
                 :aria-expanded="datePopupExpanded ? 'true' : 'false'">
            <year-month-select v-model="timelineStart"
                               @show="datePopupExpanded = true"
                               @hide="datePopupExpanded = false"/>
          </q-btn>

          <q-btn flat
                label="Author Search"
                class="q-mx-sm"
                @click="toggleAuthorSearch" />

          <q-space />

          <q-btn flat
                 label="About Us"
                 @click="toggleAboutUs"/>

          <a href="mailto:readinglikeavictorian@gmail.com"
             class="contact-button">
            <q-btn flat
                   label="Contact Us"
                   text-color="white" />
          </a>
        </q-toolbar>
        <q-toolbar dark
                  dense
                  class="page-filters bg-grey-9"
                  v-if="filtersActive">
          <div class="row q-col-gutter-x-sm items-centers jutify-center full-width">
            <div class="col">
              <q-select dark
                        dense
                        filled
                        multiple
                        clearable
                        autofocus
                        color="white"
                        label="Author Gender Identity"
                        options-dark
                        :options="genderOptions"
                        v-model="genders" />
            </div>
            <div class="col">
              <q-select dark
                        dense
                        filled
                        multiple
                        clearable
                        color="white"
                        label="Genre"
                        options-dark
                        :options="allGenres"
                        v-model="genres" />
            </div>
            <div class="col">
              <q-select dark
                        dense
                        filled
                        multiple
                        clearable
                        color="white"
                        label="Language"
                        options-dark
                        :options="allLanguages"
                        v-model="languages" />
            </div>
          </div>
        </q-toolbar>
      </nav>
    </q-header>

    <q-page-container>
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script>
import { scroll } from 'quasar'
import { mapGetters, mapMutations, mapState } from 'vuex';

import OsuNavbar from "@asctech/vue-osu-navbar";
import "@asctech/vue-osu-navbar/dist/styles.css";
import OsuFooter from "@asctech/vue-osu-footer";
import YearMonthSelect from '../components/YearMonthSelect';
import RLVLogo from '../images/rlv-logo.png';
import OSUWordmark from '../images/osu-wordmark.png';

const { getScrollTarget, setScrollPosition } = scroll;

export default {
  components: {
    OsuNavbar,
    OsuFooter,
    YearMonthSelect,
  },
  data() {
    return {
      genderOptions: [
        'Male',
        'Female',
        'Anonymous',
        'Pseudonymous',
      ],
      genreOptions: [
        'Adventure',
        'Bildungsroman',
        'Detective',
        'Domestic Realism',
        'Epistolary',
        'Fantasy',
        'Gothic',
        'Historical',
        'Mystery',
        'Naturalism',
        'New Woman',
        'Nonfiction',
        'Political Novel',
        'Science Fiction',
        'Sensation',
        'Silver-Fork Novel',
        'Social-Problem Novel',
        'Utopian',
      ],
      languageOptions: [
        'English',
        'French',
        'German',
        'Russian',
      ],
      datePopupExpanded: false,
    };
  },
  computed: {
    logoSrc() {
      return RLVLogo;
    },
    OSUWordmarkSrc() {
      return OSUWordmark;
    },
    genders: {
      get() {
        return this.genderFilter || null;
      },
      set(genders) {
        this.setGenderFilter({ genders });
      },
    },
    genres: {
      get() {
        return this.genreFilter || null;
      },
      set(genres) {
        this.setGenreFilter({ genres });
      },
    },
    languages: {
      get() {
        return this.languageFilter || null;
      },
      set(languages) {
        this.setLanguageFilter({ languages });
      },
    },
    timelineStart: {
      get() {
        return this.start;
      },
      set(date) {
        this.setStartDate(date);
      },
    },
    ...mapState('timeline', [
      'filtersActive',
      'genderFilter',
      'genreFilter',
      'languageFilter',
    ]),
    ...mapGetters('timeline', [
      'start',
      'allGenres',
      'allLanguages',
    ]),
  },
  methods: {
    ...mapMutations('timeline', [
      'setStartDate',
      'setFiltersStatus',
      'setGenderFilter',
      'setGenreFilter',
      'setLanguageFilter',
      'toggleAuthorSearch',
      'toggleAboutUs',
    ]),
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 2em;
  text-align: center;
}

.app-layout .q-page-container {
  height: 100vh;
}

a.skip-to-content-link {
    left:-999px;
    position:absolute;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;
}
a.skip-to-content-link:focus, a.skip-to-content-link:active {
    color: #fff;
    background-color:#000;
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow:auto;
    margin: 10px 35%;
    padding:5px;
    border-radius: 15px;
    border:4px solid yellow;
    text-align:center;
    font-size:1.2em;
    z-index:999;
}

.contact-button {
  // border-radius: 3px;
  // padding: 6px 16px;
  // &:hover {
  //   background: #3c3c3c;
  // }

  // &:focus {
  //   outline: 3px solid lightblue;
  // }
}

.footer {
  background: #333;
  a {
    color: #fff;
  }
  p {
    font-size: 12px;
    text-align: left;
    margin: 0;
  }
  img {
    margin-bottom: -5px;
  }
}
</style>
