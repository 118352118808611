import api from '../api/publications';

const setIncluded = (commit, included) => {
  const {
    author: authors,
    part: parts,
    source: sources,
  } = included.reduce((types, data) => {
    const type = types[data.type] || [];
    return { ...types, [data.type]: [...type, data] };
  }, {});

  if (authors) commit('authors/set', { authors }, { root: true });
  if (parts) commit('parts/set', { parts }, { root: true });
  if (sources) commit('sources/set', { sources }, { root: true });
};

export default {
  namespaced: true,
  state: {
    index: {},
    loaded: {},
  },
  getters: {
    all({ index }) {
      return Object.values(index);
    },
    find({ index }) {
      return id => index[id];
    },
  },
  mutations: {
    set(state, { publications }) {
      const insert = publications.reduce((idx, pub) => {
        return { ...idx, [pub.id]: pub };
      }, {});

      state.index = {
        ...state.index,
        ...insert,
      };
    },
    loaded(state, { id }) {
      state.loaded = { ...state.loaded, [id]: true };
    },
  },
  actions: {
    async loadPublications({ commit }) {
      try {
        const { data, included } = await api.all();

        commit('set', { publications: data });
        if (included) setIncluded(commit, included);

        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async loadPublication({ commit, state }, { id }) {
      if (state.loaded[id]) return state.index[id];

      try {
        const { data, included } = await api.get(id);

        commit('set', { publications: [data] });
        if (included) setIncluded(commit, included);

        commit('loaded', { id });

        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
