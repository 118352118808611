<template>
  <div class="timeline-grid full-width" @click="unselectPublication">
    <div class="shadow-10 bg-grey-1">
      <div class="row">
        <q-btn dense
               unelevated
               icon="keyboard_arrow_left"
               title="Move Date Backward"
               color="grey-7"
               class="col-auto q-mr-xs"
               size="sm"
               padding="xs"
               style="border-radius: 0;"
               :disable="dateBackwardDisabled"
               @click.stop.prevent="decrementStart" />
        <div class="col">
          <grid-months :dates="dates" />
          <grid-years :dates="dates" />
        </div>

        <q-btn dense
               unelevated
               icon="keyboard_arrow_right"
               title="Move Date Forward"
               color="grey-7"
               class="col-auto"
               size="sm"
               padding="xs"
               style="border-radius: 0;"
               :disable="dateForwardDisabled"
               @click.stop.prevent="incrementStart" />
      </div>
    </div>

    <div class="timeline-grid__grid">
      <grid-row v-for="(row, i) of grid" :key="i" :row="row" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import GridExpand from './TimelineGridExpand';
import GridMonths from './TimelineGridMonths';
import GridRow from './TimelineGridRow';
import GridYears from './TimelineGridYears';

export default {
  components: {
    GridExpand,
    GridMonths,
    GridRow,
    GridYears,
  },
  props: {
    showControls: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateBackwardDisabled() {
      return this.timelineStart.month === 1 && this.timelineStart.year === 1840;
    },
    dateForwardDisabled() {
      return this.timelineEnd.month === 12 && this.timelineEnd.year === 1873;
    },
    ...mapGetters('publications', {
      findPublication: 'find',
    }),
    ...mapGetters('timeline', {
      timelineStart: 'start',
      timelineEnd: 'end',
      grid: 'grid',
      dates: 'timelineDates',
      publications: 'publications',
    }),
    ...mapState('timeline', {
      selected: 'selected',
    }),
  },
  methods: {
    ...mapMutations('timeline', [
      'unselectPublication',
      'incrementStart',
      'decrementStart',
    ]),
  },
  created() {
    document.addEventListener("wheel", event => {
      if (!event.shiftKey) return;

      if (event.deltaX > 0 && !this.dateForwardDisabled) {
        this.incrementStart();
      } else if (event.deltaX < 0 && !this.dateBackwardDisabled) {
        this.decrementStart();
      }
    });

    document.addEventListener("keydown", event => {
      if (event.key === "ArrowLeft" && !this.dateBackwardDisabled) {
        this.decrementStart();
      } else if (event.key === "ArrowRight" && !this.dateForwardDisabled) {
        this.incrementStart();
      }
    });
  }
};
</script>
