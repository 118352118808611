<template>
  <q-item clickable
          v-ripple
          @click="goToPublication">
    <q-item-section>
      <q-item-label>
        {{ title }}
      </q-item-label>
      <q-item-label caption>
        {{ publishPeriod }}
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { areEqual, monthName } from '../utils/date';

export default {
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  inject: ['scrollToTimeline'],
  computed: {
    title() {
      return this.publication.attributes.title;
    },
    genre() {
      return this.publication.attributes.genre;
    },
    language() {
      return this.publication.attributes.language;
    },
    notes() {
      return this.publication.attributes.notes;
    },
    start() {
      const { month, year } = this.publication.attributes.start;

      return month ? `${monthName(month)} ${year}` : year;
    },
    end() {
      const { month, year } = this.publication.attributes.end;

      return month ? `${monthName(month)} ${year}` : year;
    },
    publishPeriod() {
      const { attributes: { start, end } } = this.publication;

      return areEqual(start, end) ? this.start : `${this.start} - ${this.end}`;
    },
  },
  methods: {
    goToPublication() {
      const { month, year } = this.publication.attributes.start;

      this.scrollToTimeline();
      this.setStartDate({ month, year });
      this.loadPublication({ id: this.publication.id });
      this.selectPublication({ id: this.publication.id });
      this.toggleAuthorSearch({ show: false });
    },
    ...mapActions('publications', [
      'loadPublication',
    ]),
    ...mapMutations('timeline', [
      'setStartDate',
      'toggleAuthorSearch',
      'selectPublication',
    ]),
  },
};
</script>
