<template>
  <q-popup-proxy ref="qDateProxy"
                 transition-show="scale"
                 transition-hide="scale"
                 @show="() => $emit('show')"
                 @hide="() => scrollToTimeline() && $emit('hide')">
    <q-date minimal
            emit-immediately
            default-view="Years"
            mask="DD/MM/YYYY"
            :value="date"
            @input="dateInput" />
  </q-popup-proxy>
</template>

<script>
import { scroll } from 'quasar'
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Object, String],
    },
  },
  computed: {
    year() {
      return this.value && this.value.year ? this.value.year : undefined;
    },
    month() {
      return this.value && this.value.month ? this.value.month : undefined;
    },
    date() {
      if (this.year && this.month) {
        return this.month > 9
          ? `01/${this.month}/${this.year}`
          : `01/0${this.month}/${this.year}`;
      }

      if (this.year) return `01/01/${this.year}`;

      return null;
    },
  },
  methods: {
    scrollToTimeline() {
      const el = document.getElementById('timeline');

      const target = getScrollTarget(el);
      const offset = el.offsetTop;
      const duration = 2000;

      setScrollPosition(target, offset, duration);

      return true;
    },
    input(value) {
      if (!value) this.$emit('input', undefined);

      const [month, year] = value.split('/').map(v => v && parseInt(v));

      this.$emit('input', { month, year });
      this.scrollToTimeline();
    },
    dateInput(value, reason, { year, month }) {
      if (reason === 'month') {
        this.$refs.qDateProxy.hide();
        this.$emit('input', { year, month });
        this.$emit('change', { year, month });
      } else if (reason === 'year') {
        this.$emit('input', { year, month: this.month });
      }
    },
  },
};
</script>
