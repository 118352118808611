<template>
  <div>
    <q-separator />

    <div class="row grid-row">
      <grid-entry v-for="entry in entries"
                  :key="entry.publication.id"
                  v-bind="entry" />
    </div>

    <q-slide-transition>
      <grid-expand :publication="selected" v-if="selected" />
    </q-slide-transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getId } from '../utils/object';
import { isNotNil } from '../utils/predicates';
import { buildRow } from '../utils/timeline';
import GridEntry from './TimelineGridEntry';
import GridExpand from './TimelineGridExpand';

export default {
  components: {
    GridEntry,
    GridExpand,
  },
  props: {
    row: {
      type: Array,
    },
  },
  computed: {
    selected() {
      const ids = this.row.filter(isNotNil).map(getId);

      if (this.selectedPub && ids.includes(this.selectedPub))
        return this.row.find(({ id }) => id === this.selectedPub);

      return undefined;
    },
    entries() {
      return buildRow({ start: this.start, end: this.end }, this.row);
    },
    ...mapGetters('timeline', [
      'start',
      'end'
    ]),
    ...mapState('timeline', {
      selectedPub: 'selected',
    }),
  },
};
</script>
