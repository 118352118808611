import Vue from 'vue';
import Vuex from 'vuex';

import authors from './authors';
import parts from './parts';
import publications from './publications';
import sources from './sources';
import timeline from './timeline';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    authors,
    parts,
    publications,
    sources,
    timeline,
  },
});

export default store;
