import { loaded } from './dom';

import 'quasar/dist/quasar.sass';
import 'quasar/dist/quasar.addon.min.css';
import iconSet from 'quasar/icon-set/material-icons.js';

import Quasar from 'quasar';
import Vue from 'vue';
import store from '../store';

const renderPage = ({ layout, page }) =>
  h => h(layout, {}, [h(page)]);

const mountVue = vm =>
  vm.$mount() && document.body.appendChild(vm.$el);

export const initVue = opts => loaded(() => {
  Vue.use(Quasar, { iconSet });

  const vm = new Vue({
    store,
    render: renderPage(opts),
  });

  mountVue(vm);
});
