import { areEqual, isAfter, isBefore, monthDifference } from './date';
import { isNotNil } from './predicates';

const buildRowEntry = (timeline, row) => (pub, idx) => {
  const endsAfterTimeline = isAfter(pub.end, timeline.end);
  const startsBeforeTimeline = isBefore(pub.start, timeline.start);

  const previous = idx === 0
    ? { end: timeline.start }
    : row[idx - 1];

  if (startsBeforeTimeline && endsAfterTimeline)
    return { length: 12, offset: 0, publication: pub };

  if (startsBeforeTimeline) {
    const length = Math.min(12, monthDifference(timeline.start, pub.end) + 1);
    return { length, offset: 0, publication: pub };
  }

  const length = endsAfterTimeline
    ? Math.min(12, monthDifference(pub.start, timeline.end) + 1)
    : Math.min(12, monthDifference(pub.start, pub.end) + 1);

  const offset = idx === 0
    ? monthDifference(timeline.start, pub.start)
    : monthDifference(previous.end, pub.start) - 1;

  return { length, offset, publication: pub };
};

export const buildRow = (timeline, row) =>
  row.filter(isNotNil).map(buildRowEntry(timeline, row));
