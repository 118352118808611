import {
  any,
  complement,
  contains,
  curry,
  equals,
  either,
  isEmpty as _isEmpty,
  isNil as _isNil,
  not,
  pipe,
  toLower,
  toString,
  type,
} from 'ramda';

export const anyTrue = arr => any(equals(true))(arr);

export const isArray = pipe(type, equals("Array"));
export const isEmpty = _isEmpty;
export const isFile = pipe(type, equals("File"));
export const isNil = _isNil;
export const isNumber = pipe(type, equals("Number"));
export const isNull = pipe(type, equals("Null"));
export const isObject = pipe(type, equals("Object"));
export const isString = pipe(type, equals("String"));
export const isUndefined = pipe(type, equals("Undefined"));
export const isFilled = complement(either(isNil, isEmpty));

export const isNotArray = complement(isArray);
export const isNotEmpty = complement(isEmpty);
export const isNotFile = complement(isFile);
export const isNotNil = complement(isNil);
export const isNotNumber = complement(isNumber);
export const isNotNull = complement(isNull);
export const isNotObject = complement(isObject)
export const isNotString = complement(isString);
export const isNotUndefined = complement(isUndefined);
export const isNotFilled = complement(isFilled);
