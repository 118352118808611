export default {
  namespaced: true,
  state: {
    index: {},
  },
  getters: {
    all({ index }) {
      return Object.values(index);
    },
    find({ index }) {
      return id => index[id];
    },
  },
  mutations: {
    set(state, { parts }) {
      const insert = parts.reduce((idx, part) => {
        return { ...idx, [part.id]: part };
      }, {});

      state.index = {
        ...state.index,
        ...insert,
      };
    },
  },
  actions: {},
};
