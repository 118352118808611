<template>
  <q-list bordered separator class="authors-list">
    <q-expansion-item v-for="author in authors"
                      :key="author.id"
                      :label="`${author.attributes.firstName} ${author.attributes.lastName}`">
      <q-card>
        <q-card-section>
          <author-publications :author="author" />
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </q-list>
</template>

<script>
import AuthorPublications from './AuthorPublications';

export default {
  components: {
    AuthorPublications,
  },
  props: {
    authors: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.authors-list {
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
