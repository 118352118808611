<script>
import { monthName } from '../utils/date';

export default {
  functional: true,
  render(h, { props: { dates } }) {
    return h('div', { class: 'row' }, dates.map(date =>
      h('div', { class: 'col-1 text-body1' }, [monthName(date)])),
    );
  },
};
</script>
