<template>
  <div :class="columnClasses">
    <publication-card :publication="publication" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { areEqual, isAfter, isBefore, monthDifference } from '../utils/date';
import PublicationCard from './PublicationCard';

export default {
  components: {
    PublicationCard,
  },
  props: {
    length: {
      type: Number,
    },
    offset: {
      type: Number,
    },
    publication: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // startsBeforeTimeline() {
    //   return isBefore(this.publication.start, this.start);
    // },
    // endsAfterTimeline() {
    //   return isAfter(this.publication.end, this.end);
    // },
    // length() {
    //   if (this.startsBeforeTimeline && this.endsAfterTimeline) return 12;

    //   if (this.startsBeforeTimeline)
    //     return Math.min(12, monthDifference(this.start, this.publication.end) + 1);

    //   if (this.endsAfterTimeline)
    //     return monthDifference(this.publication.start, this.end);

    //   return Math.min(12, monthDifference(this.publication.start, this.publication.end));
    // },
    // offset() {
    //   if (this.startsBeforeTimeline) return 0;
    //   else return monthDifference(this.start, this.publication.start);
    // },
    columnClasses() {
      return {
        [`col-${this.length ? this.length : 1}`]: true,
        [`offset-${this.offset}`]: this.offset > 0,
      };
    },
    ...mapGetters('timeline', [
      'start',
      'end'
    ]),
  },
};
</script>
