<template>
  <q-toolbar dense :class="toolbarClasses" v-if="sources.length">
    <a v-for="src in partSources"
       :key="src.id"
       :href="src.attributes.url"
       target="_blank">
      <q-btn class="q-mr-sm"
             text-color="dark"
             size="11px"
             :color="publication.color.bg"
             :label="nopartLabel(src.attributes)" />
    </a>
    <q-btn-dropdown v-for="(srcs, bvc) in chapters"
                    :key="bvc"
                    cover
                    class="q-mr-sm"
                    size="11px"
                    role="menu"
                    text-color="dark"
                    aria-haspopup="true"
                    :aria-expanded="chapterDropdownExpanded[bvc] ? 'true' : 'false'"
                    :color="publication.color.bg"
                    :label="`${bvc_string(bvc)}`"
                    @show="() => (chapterDropdownExpanded = { ...chapterDropdownExpanded, [bvc]: true })"
                    @hide="() => (chapterDropdownExpanded = { ...chapterDropdownExpanded, [bvc]: false })">
      <q-list class="sources-dropdown">
        <q-item-label header
                      :class="`text-bold text-dark`">
          {{ bvc_string(bvc) }}
        </q-item-label>

        <q-item v-for="src in srcs"
                :key="src.id"
                clickable
                v-close-popup>
          <q-item-section>
            <a :href="src.attributes.url"
               :class="`text-dark`"
               :title="`Read ${bvc_string(bvc)} on ${src.attributes.source}`"
               target="_blank"
               role="menuitem">
              <q-item-label>
                {{ src.attributes.source }}
              </q-item-label>
            </a>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </q-toolbar>
</template>

<script>
import { mapGetters } from 'vuex';
import { monthName } from '../utils/date';
import { getId } from '../utils/object';
import { isNil, isNotNil } from '../utils/predicates';

export default {
  props: {
    sources: {
      type: Array,
      required: true,
    },
    publication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chapterDropdownExpanded: {},
    };
  },
  computed: {
    partSources() {
      return this.sources.filter(({ attributes: { chapter } }) =>
        isNil(chapter),
      );
    },
    chapters() {
      const chapterSources = this.sources.filter(({ attributes: { chapter } }) =>
        isNotNil(chapter),
      );

      let sources = JSON.parse(JSON.stringify(chapterSources));
      let return_sources = {};
      for (const [key, value] of Object.entries(sources)) {
        let bvc = `${sources[key]['attributes']['book']}/${sources[key]['attributes']['volume']}/${sources[key]['attributes']['chapter']}`;
        if (typeof return_sources[bvc] == "undefined") return_sources[bvc] = [];
        return_sources[bvc].push(sources[key]);
      }

      return return_sources;
    },
    toolbarClasses() {
      return {
        'wrap': true,
        'q-gutter-sm': true,
        'publication-sources': true,
      };
    },
    console: () => console,
    window: () => window
  },
  methods: {
    bvc_string(bvc) {
      let return_array = [];
      if (bvc.split('/')[0] != 'null') return_array.push(`Book ${bvc.split('/')[0]}`);
      if (bvc.split('/')[1] != 'null') return_array.push(`Volume ${bvc.split('/')[1]}`);
      if (bvc.split('/')[2] != 'null') return_array.push(`Chapter ${bvc.split('/')[2]}`);
      return return_array.join(', ');
    },
    nopartLabel(src) {
      return src.fulltext ? "Full Text" : src.source
    }
  },
};
</script>

<style lang="scss">
.publication-sources {
  flex-wrap: wrap !important;
}

.sources-dropdown {
  a {
    text-decoration: none;
  }
}
</style>
