<template>
  <q-card flat
          square
          class="row publication-expand inset-shadow bg-grey-9"
          transition-show="slide-down"
          transition-hide="slide-up"
          @click.stop="() => undefined">
    <q-card-section class="row q-pa-md q-gutter-lg full-width justify-around items-center">
      <div class="col-md-auto mobile-hide">
        <publication-image :publication="publication" />
      </div>

      <div class="col-md-4 col-sm-12">
        <publication-info :publication="publication" />
      </div>

      <div class="col-md col-sm-12">
        <publication-parts :publication="publication" />
      </div>
    </q-card-section>

    <q-inner-loading :showing="!loaded">
      <q-spinner-cube size="6em" :color="publication.color.text" />
    </q-inner-loading>
  </q-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import PublicationImage from './PublicationImage';
import PublicationInfo from './PublicationInfo';
import PublicationParts from './PublicationParts';

export default {
  components: {
    PublicationImage,
    PublicationInfo,
    PublicationParts,
  },
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loaded() {
      return this.loadedPublications[this.publication.id];
    },
    selected() {
      return this.selectedPublication === this.publication.id;
    },
    dimmed() {
      this.selectedPublication && !this.selected;
    },
    cardClasses() {
      return {
        'cursor-pointer': true,
        'publication-cell': true,
        'publication-cell--selected': this.selected,
        'publication-cell--dimmed': this.dimmed,
        [`bg-${this.publication.color.bg}`]: true,
      };
    },
    ...mapGetters('sources', {
      allSources: 'all',
    }),
    ...mapGetters('authorPublications', {
      allAuthorPubs: 'all',
    }),
    ...mapGetters('authors', {
        allAuthors: 'all',
    }),
    ...mapState('publications', {
      loadedPublications: 'loaded',
    }),
    ...mapState('timeline', {
      selectedPublication: 'selected',
    }),
  },
  methods: {
    ...mapMutations('timeline', [
      'selectPublication',
    ]),
  },
};
</script>

<style lang="scss">
.publication-expand {
  .q-timeline {
    font-size: 0.75rem;

    .q-timeline__content {
      padding-bottom: 8px;
    }

    .q-timeline-title {
      margin-bottom: 0;
    }

    .q-toolbar {
      padding: 0 0;
    }
  }
}
</style>

