export default {
  namespaced: true,
  state: {
    index: {},
  },
  getters: {
    all({ index }) {
      return Object.values(index);
    },
    find({ index }) {
      return id => index[id];
    },
  },
  mutations: {
    set(state, { sources }) {
      const insert = sources.reduce((idx, src) => {
        return { ...idx, [src.id]: src };
      }, {});

      state.index = {
        ...state.index,
        ...insert,
      };
    },
  },
  actions: {},
};
