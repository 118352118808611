import { memoizeWith } from 'ramda';

const MONTH_NAMES = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const areEqual = (a, b) => {
  if (a.year !== b.year) return false;
  if (a.month !== b.month) return false;

  return true;
};

export const isBefore = (a, b) => {
  if (areEqual(a, b)) return false;
  if (a.year > b.year) return false;
  if ((a.year === b.year) && (a.month > b.month)) return false;

  return true;
};

export const isAfter = (a, b) => {
  if (areEqual(a, b)) return false;
  if (a.year < b.year) return false;
  if ((a.year === b.year) && (a.month < b.month)) return false;

  return true;
};

export const inDateRange = (range, start, end) => {
  if (isBefore(start, range.start) && isAfter(end, range.end)) return true;

  if (areEqual(start, end)) {
    if (isAfter(start, range.end)) return false;
    if (isBefore(start, range.start)) return false;
  }

  if (isBefore(end, range.start)) return false;
  if (isAfter(start, range.end)) return false;

  return true;
};

export const compareDates = (a, b) => {
  if (a.year > b.year) return 1;
  if (a.year < b.year) return -1;

  if (a.year === b.year) return a.month - b.month;

  return -1;
};

export const incrementDate = ({ month, year }) => ({
  year: month === 12 ? year + 1 : year,
  month: month === 12 ? 1 : month + 1,
});

export const decrementDate = ({ month, year }) => ({
  year: month === 1 ? year - 1 : year,
  month: month === 1 ? 12 : month - 1,
});

export const addMonths = memoizeWith(
  ({ year, month }, n) => `${year}${month}${n}`, // memoization key fn
  (date, n) => n === 0 ? date : addMonths(incrementDate(date), n - 1), // memoized fn
);

export const monthDifference = (a, b, total) => {
  if (areEqual(a, b)) return total || 0;
  if (!total && isBefore(b, a)) [a, b] = [b, a];

  return monthDifference(incrementDate(a), b, total ? total + 1 : 1);
};

export const monthName = date => {
  if (typeof date === 'number') return MONTH_NAMES[date];
  if (typeof date === 'object' && date.month) return monthName(date.month);
};
