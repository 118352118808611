import { flatten, memoizeWith, uniq } from 'ramda';
import { createRange, repeatArray } from '../utils/array';
import { getId } from '../utils/object';
import { isArray, isFilled, isEmpty, isNil, isNotUndefined } from '../utils/predicates';

import {
  addMonths,
  compareDates,
  decrementDate,
  incrementDate,
  inDateRange,
  isAfter,
  monthDifference,
} from '../utils/date';

const COLORS = repeatArray(10, [
  { bg: 'rlv-color-1', text: 'grey-10' },
  { bg: 'rlv-color-2', text: 'grey-10' },
  { bg: 'rlv-color-3', text: 'grey-10' },
  { bg: 'rlv-color-4', text: 'grey-10' },
  { bg: 'rlv-color-5', text: 'grey-10' },
  { bg: 'rlv-color-6', text: 'grey-10' },
  { bg: 'rlv-color-7', text: 'grey-10' },
  { bg: 'rlv-color-8', text: 'grey-10' },
  { bg: 'rlv-color-9', text: 'grey-10' },
  { bg: 'rlv-color-10', text: 'grey-10' },
  { bg: 'rlv-color-11', text: 'grey-10' },
  { bg: 'rlv-color-12', text: 'grey-10' },
  { bg: 'rlv-color-13', text: 'grey-10' },
  { bg: 'rlv-color-14', text: 'grey-10' },
  { bg: 'rlv-color-15', text: 'grey-10' },
  { bg: 'rlv-color-16', text: 'grey-10' },
  { bg: 'rlv-color-17', text: 'grey-10' },
  { bg: 'rlv-color-18', text: 'grey-10' },
]);

// To assign colors to publications consitently while
// also trying to avoid multiple publications in the same
// timeline range having the same color the publications
// title is hashed into a string of numbers, then two of
// those numbers are plucked from the middle (e.g. '08', '73', '29')
// which are then used to select a color from the COLORS array
// above using the plucked number as the index. This gives
// a varied enough distribution of colors, without having to
// indiviually assign a color to a publication, as well as
// ensure the same color will be selected for a publication
// everytime it comes into the timeline range and is placed
// on the grid or when time range on the grid updates and it
// has to shift position
const hashPublicationTitle = memoizeWith(t => t, title => {
  const hashFn = (h, i) => Math.imul(h ^ title.charCodeAt(i), 2654435761);
  const hashVal = createRange(0, title.length).reduce(hashFn, 0xdeadbeef);

  return ((hashVal ^ hashVal >>> 16) >>> 0).toString();
});

const selectPublicationColor = memoizeWith(t => t, title => {
  const hash = hashPublicationTitle(title);
  const index = parseInt(hash.slice(5, 7));

  return COLORS[index];
});

const expandAttrs = ({ id, attributes } = {}) =>
  id ? { id, attributes, ...attributes } : undefined;

const findRelations = ({ data } = {}, find) => {
  if (!data) return undefined;

  if (isArray(data)) {
    return data.map(getId)
      .map(find)
      .map(expandAttrs)
      .filter(isNotUndefined);
  }

  return expandAttrs(find(data.id));
}

const sortParts = parts =>
  parts.filter(p => p.year && p.month).sort(compareDates);

const findBoundry = (parts, bound) => {
  const select = (a, b) =>
    bound === 'min' ? Math.min(a, b) : Math.max(a, b);

  const initial = bound === 'min'
    ? { year: Infinity, month: 12 }
    : { year: -Infinity, month: 1 };

  const compare = bound === 'min'
    ? (year, current) => year <= current
    : (year, current) => year >= current

  return parts.reduce((current, part) => {
    if (!part || (!part.year && !part.month)) return current;

    const year = select(current.year, part.year);
    const month = compare(part.year, current.year)
      ? part.year === current.year
        ? select(current.month, part.month)
        : part.month
      : current.month;

    return { year, month };
  }, initial);
};

const getPublicationStartEnd = parts => {
  const start = findBoundry(parts, 'min')
  const end = findBoundry(parts, 'max')

  return { start, end };
};

const buildTimelineGrid = ([first, ...pubs]) =>
  pubs.reduce((grid, pub) => {
    const index = grid.findIndex(row => {
      const last = row[row.length - 1];
      return isAfter(pub.start, last.end);
    });

    if (index < 0) return [...grid, [pub]];

    const row = grid[index];

    return [
      ...grid.slice(0, index),
      [...row, pub],
      ...grid.slice(index + 1),
    ]
  }, [[first]]);

const filterByGender = (pub, genders) =>
  (genders && genders.length)
    ? pub.gender.find(g => genders.includes(g))
    : true;


const filterByGenre = (pub, genres) =>
  (genres && genres.length)
    ? pub.genre.find(g => genres.includes(g))
    : true;

const filterByLanguage = (pub, languages) =>
  (languages && languages.length)
    ? pub.language.find(l => languages.includes(l))
    : true;

const setTimelineRange = (state, start) => {
  state.startYear = start.year;
  state.startMonth = start.month;
};

export default {
  namespaced: true,
  state: {
    loaded: false,
    selected: undefined,
    startMonth: 10,
    startYear: 1847,
    timelineMonths: 12,
    showAuthorSearch: false,
    showAboutUs: false,
    authorNameFilter: undefined,
    filtersActive: false,
    genderFilter: undefined,
    genreFilter: undefined,
    languageFilter: undefined,
  },
  getters: {
    start({ startMonth: month, startYear: year }) {
      return { month, year };
    },
    timelineDates({ timelineMonths: months }, { start }) {
      return createRange(0, months).map(n => addMonths(start, n));
    },
    end(_state, { timelineDates }) {
      return timelineDates[timelineDates.length - 1];
    },
    allPublications(_state, _getters, rootState, rootGetters) {
      const pubs = rootGetters['publications/all'];

      const findAuthor = rootGetters['authors/find'];
      const findPart = rootGetters['parts/find'];
      const findSource = rootGetters['sources/find'];

      return pubs.map(({ id, attributes, relationships: rels }, i) => {
        const authors = findRelations(rels.authors, findAuthor) || [];
        const parts = sortParts(findRelations(rels.parts, findPart));
        const sources = findRelations(rels.sources, findSource) || [];
        const color = selectPublicationColor(attributes.title);

        return {
          id,
          authors,
          parts,
          sources,
          color,
          attributes,
          ...attributes,
        };
      });
    },
    allGenres(_state, { allPublications }) {
      const genres = allPublications.map(({ genre }) => genre);

      return uniq(flatten(genres)).sort();
    },
    allLanguages(_state, { allPublications }) {
      const langs = allPublications.map(({ language }) => language);

      return uniq(flatten(langs)).sort();
    },
    publicationsInRange(_state, { start, end, allPublications: pubs }) {
      return pubs.filter(p => inDateRange({ start, end }, p.start, p.end));
    },
    publications(state, { publicationsInRange: pubs }) {
      if (!state.filtersActive)
        return pubs.sort((a, b) => compareDates(a.start, b.start));

      return pubs.filter(pub => filterByGender(pub, state.genderFilter))
        .filter(pub => filterByGenre(pub, state.genreFilter))
        .filter(pub => filterByLanguage(pub, state.languageFilter))
        .sort((a, b) => compareDates(a.start, b.start));
    },
    grid(_state, { publications }) {
      return buildTimelineGrid(publications);
    },
  },
  mutations: {
    setLoaded(state) {
      state.loaded = true;
    },
    setStartDate(state, { year, month }) {
      state.startYear = year || state.startYear;
      state.startMonth = month || state.startMonth;
    },
    selectPublication(state, { id }) {
      state.selected = id;
    },
    unselectPublication(state) {
      state.selected = undefined;
    },
    toggleAuthorSearch(state, { show }) {
      show = show || !state.showAuthorSearch;
      state.showAuthorSearch = show;
    },
    toggleAboutUs(state, { show }) {
      show = show || !state.showAboutUs;
      state.showAboutUs = show;
    },
    setAuthorFilter(state, { value }) {
      state.authorNameFilter = value;
    },
    setGenderFilter(state, { genders }) {
      if (isFilled(genders)) state.genderFilter = genders;
      else state.genderFilter = undefined;
    },
    setGenreFilter(state, { genres }) {
      if (isFilled(genres)) state.genreFilter = genres;
      else state.genreFilter = undefined;
    },
    setLanguageFilter(state, { languages }) {
      if (isFilled(languages)) state.languageFilter = languages;
      else state.languageFilter = undefined;
    },
    setFiltersStatus(state, { active }) {
      state.filtersActive = active ? active : false;
    },
    incrementStart(state) {
      const { startMonth, startYear, endYear, endMonth } = state;
      const start = incrementDate({ year: startYear, month: startMonth });

      setTimelineRange(state, start);
    },
    decrementStart(state) {
      const { startMonth, startYear, endYear, endMonth } = state;
      const start = decrementDate({ year: startYear, month: startMonth });

      setTimelineRange(state, start);
    },
  },
};
