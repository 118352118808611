<template>
  <div class="text-grey-2 publication-parts">
    <q-scroll-area style="height: 300px;">
      <q-timeline dark :color="publication.color.bg">
        <transition-group name="list-complete" tag="div">
          <publication-part v-for="part in parts"
                            :id="part.id"
                            :key="part.id"
                            :publication="publication" />
        </transition-group>
      </q-timeline>
    </q-scroll-area>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PublicationPart from './PublicationPart';

export default {
  components: {
    PublicationPart,
  },
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    parts() {
      return this.publication.parts.sort((a, b) =>
        a.attributes.part - b.attributes.part,
      );
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.publication-parts {
  .q-timeline {
    font-size: 0.75rem;

    .q-timeline__content {
      padding-bottom: 8px;
    }

    .q-timeline__title {
      font-size: 1rem;
    }

    .q-timeline__title,
    .q-timeline__subtitle {
      margin-bottom: 4px;
    }

    .q-toolbar {
      padding: 0 0;
      min-height: 30px;
    }
  }
}

.list-enter-active, .list-leave-active {
  transition: all .35s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}

.list-complete-enter-active, .list-complete-leave-active {
  transition: all .5s;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>
