<template>
  <q-card flat
          square
          bordered
          :class="cardClasses"
          @click.stop="select"
          v-ripple>
    <div :class="sectionClasses">
      <div class="col-auto text-h6 publication-card__title">
        {{ title }}
      </div>
      <div class="col-auto text-subtitle1 desktop-only publication-card__authors q-mt-sm"
           v-if="authors && authors.length">
        {{ authors }}
      </div>
    </div>
    <div style="text-align: center;" v-if="selected">
      <q-icon name="keyboard_arrow_down" size="16px" />
    </div>
  </q-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  computed: {
    id() {
      return this.publication.id;
    },
    title() {
      return this.publication.attributes.title;
    },
    authors() {
      return this.publication.authors
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(', ');
    },
    selected() {
      return this.selectedId === this.id;
    },
    dimmed() {
      return this.selectedId && !this.selected;
    },
    cardClasses() {
      return {
        'full-height': true,
        'cursor-pointer': true,
        'publication-card': true,
        'publication-card--selected': this.selected,
        'publication-card--dimmed': this.dimmed,
        'dimmed': this.dimmed,
        [`bg-${this.publication.color.bg}`]: true,
      };
    },
    sectionClasses() {
      return [
        'column',
        'items-center',
        'justify-center',
        `text-${this.publication.color.text}`,
      ];
    },
    titleClasses() {
      return [
        'text-h6',
        `text-${this.publication.color.text}`,
        'publication-card__title',
      ];
    },
    ...mapState('timeline', {
      selectedId: 'selected',
    }),
  },
  methods: {
    select() {
      if (this.selected) return this.unselectPublication();

      this.loadPublication({ id: this.id });
      this.selectPublication({ id: this.id });
    },
    ...mapActions('publications', [
      'loadPublication',
    ]),
    ...mapMutations('timeline', [
      'selectPublication',
      'unselectPublication',
    ]),
  },
};
</script>

<style lang="scss">
.publication-card {
  padding: 8px;
  text-align: center;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: .65em;
  }

  &__title,
  &__authors {
    color: black;
    line-height: 1rem;
  }

  &__authors {
    font-size: 14px;
  }

  &--dimmed {
    opacity: 0.5;
  }

  &--selected {
    padding-bottom: 0;
  }

  &__title {
    font-size: 1rem;
  }

  &__subtitle {
    font-size: 0.75rem;
  }

  &:hover {
    opacity: 0.95;
  }
}
</style>
