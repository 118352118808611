import { http } from '../utils/http';

export default {
  all: async () => {
    const resp = await http.get('/api/v1/publications');
    const json = await resp.json();

    return json;
  },
  get: async id => {
    const resp = await http.get(`/api/v1/publications/${id}`);
    const json = await resp.json();

    return json;
  },
};
