import api from '../api/authors';

const setIncluded = (commit, included) => {
  const {
    part: parts,
    publications: publication,
    source: sources,
  } = included.reduce((types, data) => {
    const type = types[data.type] || [];
    return { ...types, [data.type]: [...type, data] };
  }, {});

  if (parts) commit('parts/set', { parts }, { root: true });
  if (publications) commit('publications/set', { publications }, { root: true });
  if (sources) commit('sources/set', { sources }, { root: true });
};

export default {
  namespaced: true,
  state: {
    index: {},
  },
  getters: {
    all({ index }) {
      return Object.values(index);
    },
    find({ index }) {
      return id => index[id];
    },
  },
  mutations: {
    set(state, { authors }) {
      const insert = authors.reduce((idx, author) => {
        return { ...idx, [author.id]: author };
      }, {});

      state.index = {
        ...state.index,
        ...insert,
      };
    },
  },
  actions: {
    async loadAuthors({ commit }) {
      try {
        const { data, included } = await api.all();

        commit('set', { publications: data });
        if (included) setIncluded(commit, included);

        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
