<template>
  <div class="publication-img">
    <q-img :src="imgsrc"
           :alt="imgalt"
           no-default-spinner
           transition="slide-right"
           style="width: 160px; height: 250px;"
           v-if="imgsrc" />
  </div>
</template>

<script>
export default {
  props: {
    publication: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imgsrc() {
      return this.publication.attributes.coverUrl;
    },
    imgalt() {
      return `${this.publication.attributes.title} cover`;
    }
  }
};
</script>

<style lang="scss">
</style>
