<template>
  <q-timeline-entry :title="date" :subtitle="title">
    <part-sources :sources="sources" :publication="publication" />
    <q-card bordered
            class="part-notes q-my-sm bg-grey-7"
            v-if="notes.length">
      <q-card-section>
        <div class="part-notes__title text-grey-1 text-subtitle1">
          Editor Notes
        </div>
      </q-card-section>
      <q-card-section v-for="(note, i) in notes"
                      :key="i"
                      class="text-body1"
                      v-html="note" />
    </q-card>
  </q-timeline-entry>
</template>

<script>
import { mapGetters } from 'vuex';
import { monthName } from '../utils/date';
import { getId } from '../utils/object';
import { isNotNil } from '../utils/predicates';

import PartSources from './PartSources';

export default {
  components: {
    PartSources,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    publication: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    part() {
      return this.findPart(this.id);
    },
    number() {
      return this.part.attributes.part;
    },
    title() {
      return this.number ? `Part ${this.number}` : '';
    },
    date() {
      const { month, year } = this.part.attributes;

      return month && year ? `${monthName(month)} ${year}` : year;
    },
    sources() {
      const sources = this.part.relationships.sources.data || [];

      return sources.map(getId)
                    .map(this.findSource)
                    .filter(isNotNil);
    },
    notes() {
      return this.sources.map(({ attributes: { notes } }) => notes)
                         .filter(isNotNil);
    },
    ...mapGetters('parts', {
      findPart: 'find',
    }),
    ...mapGetters('sources', {
      findSource: 'find',
    }),
  },
  methods: {},
};
</script>

<style lang="scss">
.part-notes {
  &__title {
    font-size: .85rem;
  }

  .q-card__section {
    padding: 4px 16px;

    &:last-child {
      padding-top: 0;
    }
  }
}
</style>
